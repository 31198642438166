
import MessagesTypes from "@evercam/shared/constants/iframeMessagesTypes"
import { updateQuery, toQueryString } from "@evercam/shared/utils"
import { TAGS_TYPES, PANO_VIEWER_TYPE } from "@evercam/3d/components/constants"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"

import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { useWeatherStore } from "@/stores/weather"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { mapStores } from "pinia"

import SnapshotEditor from "@/components/SnapshotEditor"
import ContextMenu from "@/components/3dAppsDialogs/ContextMenu"
import FrameViewer from "@/components/3dAppsDialogs/FrameViewer"
import CompareExportDialog from "@/components/CompareExportDialog"
import CreateUploadDialog from "@/components/ingest/uploadDialogs/CreateUploadDialog"

export default {
  name: "FrameViewerOverlay",
  components: {
    SnapshotEditor,
    ContextMenu,
    FrameViewer,
    CompareExportDialog,
    CreateUploadDialog,
  },
  props: {
    iframeData: {
      type: Object,
      default: () => {},
    },
    is360View: {
      type: Boolean,
      default: false,
    },
    isDroneView: {
      type: Boolean,
      default: false,
    },
    isBimView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEditingImage: false,
      contextMenuInfo: null,
      isPanoViewEnabled: false,
      panoViewerMode: null,
      panoViewQueryString: "",
      compareOptions: {},
      uploadDialog: false,
      uploadObject: null,
      ingestUploadType: null,
    }
  },
  computed: {
    ...mapStores(
      useProjectStore,
      useCameraStore,
      useAccountStore,
      useWeatherStore,
      useIngestFileUploaderStore
    ),
    selectedCamera() {
      return this.projectStore.selectedProjectCameras?.[0] || {}
    },
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
  },
  watch: {
    iframeData: {
      handler(data) {
        this.handleIframeMessage(data)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async handleIframeMessage(data) {
      if (!data) {
        return
      }

      const handlers = {
        [MessagesTypes.editSnapshot]: () => this.openImageEditor(data.url),
        [MessagesTypes.weather]: () => this.openWeatherDialog(data),
        [MessagesTypes.history]: () => this.updateQueryParams(data.history),
        [MessagesTypes.shareLink]: () => this.copyShareLink(),
        [MessagesTypes.cameraInfo]: () => this.openContextMenu(data),
        [MessagesTypes.createCompare]: () => this.exportCompare(data),
        [MessagesTypes.analyticsEvent]: () =>
          this.saveAnalyticsEvent(data.action),
        [MessagesTypes.uploadDrone]: () =>
          this.generateUploadLink({
            provider: IngestApi.drone.createUpload,
            uploadType: INGEST_UPLOADS_TYPES.droneUploads,
          }),
        [MessagesTypes.uploadBIM]: () =>
          this.generateUploadLink({
            provider: IngestApi.bim.createUpload,
            uploadType: INGEST_UPLOADS_TYPES.bimUploads,
          }),
      }

      const handler = handlers[data.type]

      if (handler) {
        handler()
      }
    },
    openPanoView(metadata) {
      const [dateId, floor, marker] = metadata.pano?.split("-") || []
      this.panoViewQueryString = toQueryString({
        dateId,
        floor,
        marker,
        viewerMode: this.panoViewerMode,
        projectId: this.projectStore.selectedProject?.exid,
      })
      this.isPanoViewEnabled = true
      this.contextMenuInfo = null
    },
    closePanoView() {
      this.isPanoViewEnabled = false
      this.panoViewerMode = null
    },
    async setContextMenuInfo({ position, cam: camera, data }) {
      this.contextMenuInfo = {
        x: position?.x,
        y: position?.y,
        camera,
        data,
      }
    },
    openContextMenu(data) {
      const cameraInfoType = data?.data?.type

      if (cameraInfoType === TAGS_TYPES.aerialshot) {
        this.panoViewerMode = PANO_VIEWER_TYPE.aerialshot
      } else if (cameraInfoType === PANO_VIEWER_TYPE._360Path) {
        this.panoViewerMode = PANO_VIEWER_TYPE._360Path
      }
      this.setContextMenuInfo(data)
    },
    openImageEditor(urlData) {
      if (this.isEditingImage) {
        return
      }

      this.currentSnapshot = {
        snapshot: {
          data: urlData,
          createdAt: new Date(),
        },
        camera: this.selectedCamera,
      }
      this.isEditingImage = true
    },
    openWeatherDialog(data) {
      this.weatherStore.targetTimestamp = data.timestamp
      this.weatherStore.changeWeatherVisibility(true)
    },
    saveAnalyticsEvent(action) {
      const contexts = {
        ["360"]: this.is360View,
        ["drone_view"]: this.isDroneView,
        ["4d_view"]: this.isBimView,
      }
      const actionContext = Object.keys(contexts).find((key) => contexts[key])
      this.$analytics.saveEvent(`${action}_${actionContext}`)
    },
    copyShareLink() {
      const link = window.location.href
      navigator.clipboard.writeText(link)
      this.$notifications.success("Link copied to clipboard")
    },
    updateQueryParams(params) {
      updateQuery(params, false)
    },
    exportCompare({ data }) {
      this.compareOptions = data
      this.$root.$emit("toggle-compare-dialogue")
    },
    async generateUploadLink({ provider, uploadType }) {
      if (!this.projectStore.selectedProjectExid) {
        return
      }

      try {
        const response = provider(this.projectStore.selectedProjectExid, {
          uploadedBy: this.accountStore.email,
        })
        this.uploadObject = {
          ...response,
          projectName: this.projectStore.selectedProject.name,
        }
        const uploadStats = {
          percentage: 0,
          totalSize: 0,
          uploadedSize: 0,
          isUploading: false,
          isProcessing: false,
          uploadFinished: false,
          uploadType,
        }
        this.ingestFileUploaderStore.currentUploadStats = uploadStats
        this.ingestFileUploaderStore.uploadStats.push(uploadStats)
        this.uploadDialog = true
        this.ingestUploadType = uploadType
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.ingest.upload.create_upload_failed"),
          error,
        })
      }
    },
    onCloseUploadDialog() {
      this.uploadDialog = false
      this.uploadObject = null
      this.ingestUploadType = null
    },
  },
}
